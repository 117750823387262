<!-- 时效设置弹窗 -->
<template>
  <el-dialog
      width="60%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'时效设置':'时效设置'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="150px">
      <el-row :gutter="18">
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="平台接单提醒:" prop="nickname">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.platform_join_remind.normal"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.platform_join_remind.assess"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="客服响应:" prop="nickname">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.service_response_remind.normal"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.service_response_remind.assess"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
      <el-row :gutter="18">
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="司机接单:" prop="nickname">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.technician_join_remind.normal"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.technician_join_remind.assess"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="平台派单:" prop="nickname">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.platform_dispatch_remind.normal"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.platform_dispatch_remind.assess"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
      <el-row :gutter="18">
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="司机接单至出发提醒:" prop="nickname">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.technician_depart_remind.normal"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.technician_depart_remind.assess"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="司机出发至到达提醒:" prop="nickname">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.technician_arrive_remind.normal"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.technician_arrive_remind.assess"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
      <el-row :gutter="18">
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="司机到达至完成提醒:" prop="nickname">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.technician_accomplish_remind.normal"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.technician_accomplish_remind.assess"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="订单完成至回访提醒:" prop="nickname">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.technician_return_remind.normal"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.technician_return_remind.assess"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
      <el-row :gutter="18">
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="下单至司机接单提醒:" prop="nickname">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.place_technician_join_remind.normal"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.place_technician_join_remind.assess"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="下单至司机出发提醒:" prop="nickname">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.place_technician_depart_remind.normal"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.place_technician_depart_remind.assess"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
      <el-row :gutter="18">
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="下单至司机到达提醒:" prop="nickname">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.place_technician_arrive_remind.normal"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.place_technician_arrive_remind.assess"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="下单至完成提醒:" prop="nickname">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.place_technician_accomplish_remind.normal"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                style="width: 140px;"
                clearable
                :maxlength="20"
                v-model="form.place_technician_accomplish_remind.assess"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>

    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {Saveservice_aging} from "@/api/yunli";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    id:Number,
    pid:Number
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            // id: this.pid,
            config: this.form
          }
          Saveservice_aging(this.id,data).then(res => {
            if(res.code == 200) {
              this.$message.success(res.msg)
              this.loading = true;
              this.updateVisible(false);
              this.$emit('done');
              this.loading = false;
            }else {
              this.$message.error(e.msg)
            }
          }).catch(e => {
            this.$message.error(e.msg)
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
  }
}
</script>

<style scoped>
</style>
